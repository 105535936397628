@import "./theme.custom.less";

.shipping-global-container {
  display: flex;
  justify-content: center;
  height: 86vh;
  align-items: center;
}

.shipping-align-container {
  width: 85%;
  max-width: 450px;
}

.shipping-global-container {
  .small-title {
    font-weight: bold;
    font-size: 1.5rem;
  }

  .action-buttons {
    margin-top: @spacing * 5;
    display: flex;
  }

  .shipping-field-container {
    margin-bottom: @spacing * 3;
  }

  .ship-radio-container {
    display: flex;
    width: 100%;

    .ant-radio-button-wrapper {
      width: 50%;
      text-align: center;
    }
  }
}

