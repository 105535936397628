@import './theme.custom.less';

.order-item {
  margin: 20px 0;
  box-shadow: @box-shadow-base;

  & .ant-card-grid {
    padding: 8px;
    border: none!important;
    box-shadow: none;

    & h4.ant-typography, & h5.ant-typography {
      margin-bottom: 0;
      font-size: 110%;
    }

    & span.ant-typography {
      margin-right: 10px;
    }

    & span.ant-typography.ant-typography-secondary {
      font-size: 80%
    }
  }

  & .ant-card-actions {
    //display: flex;
    border: none;

    & > li {
      margin: 0 0 10px 0;
      border: none!important;
      height: 40px;      
      display: flex;
      align-items: center;

      &:first-child {
        padding-left: 10px
      }

      &:last-child {
        & > * {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  & .order-item-logo {
    img {
      height: 40px;
      float: 'right'
    }
  }
}

.order-details {
  margin-top: 10px;

  .order-details-title {
    font-size: 110%;
    line-height: 15px;
    em {
      font-size: 66%
    }
  }
  
  .ant-page-header {
    padding: 0;

    .ant-page-header-heading-extra {
      display: flex;

      .order-details-num {
        align-self: center;
        font-size: 20px;
        font-weight: bolder;
        border-radius: 50px;
        color: white;
        background-color: @primary-color;
        padding: 1px 10px;
      }
    }
  }

  .ant-page-header-heading-title, .ant-page-header-back-button {
    color: @primary-color;
  }
}

.drawer-order-details.deactivated {
  .ant-drawer-content {
    background-color: lighten(@primary-color, -3%);
  }

  .ant-table {
    background-color: transparent;
    color: white;
  }

  .ant-page-header-heading-title, .ant-page-header-back-button {
    color: white;
  }
}
