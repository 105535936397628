@import './theme.custom.less';

.login-wrapper {
  height: 100%;
  padding: @spacing;
}

.login-logo {
  display: block;
  width: 18rem;
  margin: auto;
}


