@import './theme.custom.less';

@layout-header-height: 60px;

.app-layout {
  .app-header {
    position: fixed;
    background-color: #f7f2ef;
    padding: 0 5px;
    height: @layout-header-height;
    top: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 0 20px #dddddd;
    
    & h2 {
      color: @heading-color;
      text-align: center;
      font-size: 200%;
    }

    & .ant-btn-icon-only.ant-btn-lg {
      width: 48px;
      height: 48px;
      margin-top: 3px;

      & > * {
        font-size: 40px;
      }
    }
  }

  .app-content {
    margin-top: @layout-header-height;
    margin-bottom: 40px;

    .app-wrapper {
      & > * {
        margin-bottom: 40px;
      }
    }
  }

  .app-footer {
    z-index: 999;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  
  .app-wrapper {
    height: 100%;
    padding: 0 @spacing;
  }

  .right {
    text-align: right;
  }

  .left {
    text-align: left;
  }
  
  .app-logo {
    height: 38px;
  }
}

.app-menu {
  border: none!important;
}
