@import '~antd/dist/antd.less';
@import './theme.custom.less';

.ant-layout {
  height: 100%;
  background-color: @background-base;
}

.ant-layout-footer {
  padding: 10px 25px;
}
