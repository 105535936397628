.filter-tags-container {
  display: flex;
  justify-content: flex-end;
  padding: 11px 0px;
  height: 60px;
}

.filter-tag {
  border: 1px solid #ddd;
  border-radius: 2px;
  width: 40px;
  height: 37px; 
  /* padding: 2px 15px; */
  margin: 0px 7px;
  transition: color 300ms ease-in-out, background 300ms ease-in-out, border 300ms ease-in-out;
}

.filter-tag.active {
  background: #db8556;
  color: #fff;
  border: 1px solid #db8556;  
}

.filter-tag:hover {
  cursor: pointer;
  background: #db8556;
  color: #fff;
  border: 1px solid #db8556;
}

.filter-tag span {
  display: block;
  text-align: center;
}

.filter-tag-label {
  line-height: 14px;
  font-size:.7rem;
}

.filter-tag-value {
  font-weight: bold;
  line-height: 22px;
}