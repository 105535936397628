@import './theme.custom.less';

.simple-header-container {
  background-color: @primary-color;
  margin: 0 -16px;
  padding: 8px @spacing!important;
  
  .ant-typography {
    margin-bottom: 0;
    color: white;
    font-weight: normal;
    font-size: 1rem;
  }
}
