@import './theme.custom.less';

.rest-card {
  .ant-card-body {
    padding: 20px 8px;
  }
  .rest-card-number {
    font-weight: bold;
  }
}
