@import "./theme.custom.less";

.ant-collapse-item-active {
  .ant-collapse-header {
    color: white !important;
    background-color: @secondary-color;
  }
}

.packinglist-header-sku,
.packinglist-header-name {
  display: inline;
  margin: 0 0.5rem;
}

.packinglist-header-sku {
  font-size: 1rem;
  font-weight: bold;
}

.packinglist-header-name {
  font-size: 0.8rem;
}

.ant-tag.packinglist-header-tag {
  background-color: @primary-color;
  font-weight: bold;
  float: right;
  color: white;
  border: none;
}

.order-action-sider {
  button {
    margin: 0.5rem 0;
    &:first-child {
      margin-bottom: 0.5rem;
      margin-top: 0;
    }
    &:last-child {
      margin-top: 0.5rem;
      margin-bottom: 0;
    }
  }
}

.innpakking-item {
  padding: 16px;
  border: solid 1px #29b294;
  border-radius: 16px;
  margin-bottom: 16px;
}

